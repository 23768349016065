import {required} from 'vuelidate/lib/validators'
import { mapGetters,} from 'vuex';

export default {
    props: ['popupType', 'selectedItem'],
    components:{
        listStatuses: ()=> import('../listStatuses/index.vue')
    },
    data() {
        return {
            form: {
                ukrposhta_title: 'ukrPosta',
                ukrposhta_module: 'ukrPosta',
                ukrposhta_description: 'УкрПошта апі токен',
                ukrposhta_status: 1,
                ukrposhta_token: '',
                ukrposhta_bearer: '',
                beer_tracking: '',
                ukrposhta_list_matched_statuses: [{
                    crm_status:'',
                    order_status:''
                }],
            },
        }
    },

    validations: {
        form: {
            ukrposhta_title: {
                required
            }
        }
    },
    computed: {
        ...mapGetters({
            options:'config/options',
        }),
    },
    created() {
        this.form.ukrposhta_token = this.options.models.ukrposhta_token;
        this.form.ukrposhta_bearer = this.options.models.ukrposhta_bearer;
        this.form.beer_tracking = this.options.models.beer_tracking;
        this.form.ukrposhta_list_matched_statuses = this.options.models.ukrposhta_list_matched_statuses ? this.options.models.ukrposhta_list_matched_statuses : [{
            crm_status:'',
            order_status:''
        }];
    },
    methods: {
        closePopup() {
            this.$emit('closePopup')
        },
        addToStatusList(){
            this.form.ukrposhta_list_matched_statuses.push({
                srm_status: '',
                order_status: ''
            })
        },
        removeStatusItem(item){
            const index = this.form.ukrposhta_list_matched_statuses.indexOf(item);
            this.form.ukrposhta_list_matched_statuses.splice(index, 1);
        },
        sendConfirm() {
            if(this.warehouse){
                this.$emit('changeOptions', this.form)
            }else{
                this.$emit('sendOptions', this.form)
            }
        },
    },
    destroyed() {
        this.changeShowWarehouse(null);
    }
}
